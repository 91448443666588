//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskSubmit } from '@/api/modular/flowable/handleTaskManage'
import moment from 'moment'
import { sysUserSelector } from '@/api/modular/system/userManage'
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      recordData: {},
      buttonList: {},
      formDataReq: {},
      userSelector: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    moment,
    /**
     * 初始化方法
     */
    open(record, buttonList, formDataReq) {
      this.formDataReq = formDataReq
      this.buttonList = buttonList
      this.recordData = record
      this.visible = true
      if (this.buttonList.nextBtn === 'Y') {
        this.sysUserSelector()
      }
    },
    sysUserSelector() {
      sysUserSelector().then(res => {
        this.userSelector = res.data
      })
    },
    /**
     * 提交
     */
    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      validateFields((errors, values) => {
        if (!errors) {
          if (values.nextDueDate != null) {
            values.nextDueDate = moment(values.nextDueDate).format('YYYY-MM-DD HH:mm:ss')
          } else {
            delete values.nextDueDate
          }
          if (values.nextPriority === 0) {
            delete values.nextPriority
          }
          this.confirmLoading = true
          values.processDefinitionId = this.recordData.procIns.id
          values.taskId = this.recordData.id
          values.variables = this.formDataReq
          handleTaskSubmit(values)
            .then(res => {
              if (res.success) {
                this.$message.success('審批成功')
                this.returnPage()
              } else {
                this.$message.error('審批失敗：' + res.message)
              }
            })
            .finally(res => {
              this.confirmLoading = false
            })
        }
      })
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage() {
      this.visible = false
      this.form.resetFields()
      this.$emit('close')
    },
    /**
     * 關閉
     */
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
